import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import "./image-carousel.scss"


const ImageCarousel = ({ images, img }) => {

    return (
        <>
            <div className="image-carousel-container">
                <GatsbyImage image={img} alt="Cheap Price Stamp" className="image-spacing"></GatsbyImage>
            </div>
        </>
    )
}

export default ImageCarousel